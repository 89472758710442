<footer class="footer footer-2">
    <div class="container">
        <hr class="mb-0 mt-0">
    </div>
    <div class="footer-middle border-0">
        <div [ngClass]="containerClass">
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <div class="widget widget-about">
                        <img src="assets/images/home/logo-footer.png" class="footer-logo" alt="Footer Logo"  width="180" height="50">
                        <p>MYLITELED è un marchio NOVA PUBBLICITA' Srl Unipersonale<br>Via S.Bocconcello, 6 Meledo di Sarego (VI)</p>
                        <p>Partita IVA 03217420243</p>
                        <div class="widget-about-info">
                            <div class="row">
                                <div class="col-sm-6 col-md-4">
                                    <span class="widget-about-title">Contattaci</span>
                                    <a href="tel:00390444821261">+39 0444 82 12 61</a>
                                </div>
                                <div class="col-sm-6 col-md-8">
                                    <span class="widget-about-title">Payment Method</span>
                                    <figure class="footer-payments">
                                        <img src="assets/images/payments-3.png" alt="Payment methods" width="115"
                                            height="18" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">Info</h4>

                        <ul class="widget-list">
                            <li><a routerLink="pages/about">Chi siamo</a></li>
                            <li><a routerLink="pages/faq" class="d-none">FAQ</a></li>
                            <li><a routerLink="pages/contact">Contattaci</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">My Account</h4>

                        <ul class="widget-list">
                            <li><a routerLink="/pages/register">Registrazione</a></li>                            
                            <li><a routerLink="pages/login">Login</a></li>
                            <li><a routerLink="/shop/cart">Carrello</a></li>
                            <li><a routerLink="/shop/wishlist">Wishlist</a></li>
                            <!--li><a href="javascript:;">Track My Order</a></li>
                            <li><a href="javascript:;">Help</a></li-->
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-2">
                    <div class="widget">
                        <h4 class="widget-title">Customer Service</h4>

                        <ul class="widget-list">
                            <li><a href="/assets/download/condizioni-di-vendita-ESHOP_MYLITELED.pdf"
                                target="_blank">Pagamenti</a></li>
                            <li><a href="/assets/download/condizioni-di-vendita-ESHOP_MYLITELED.pdf"
                                target="_blank">Resi</a></li>
                            <li><a href="/assets/download/condizioni-di-vendita-ESHOP_MYLITELED.pdf"
                                target="_blank">Spedizione</a></li>
                            <li><a href="/assets/download/condizioni-di-vendita-ESHOP_MYLITELED.pdf"
                                    target="_blank">Termini e Condizioni</a></li>
                            <li><a target="_blank" href="https://www.iubenda.com/privacy-policy/147874"
                                    class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                                    title="Privacy Policy ">Privacy Policy</a></li>
                            <li><a target="_blank" href="https://www.iubenda.com/privacy-policy/147874/legal"
                                    class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                                    title="Cookie Policy ">Cookie Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div [ngClass]="containerClass">
            <p class="footer-copyright">Copyright &copy; {{ year }}. All Rights Reserved.</p>
            <ul class="footer-menu">
                <li><a href="/assets/download/condizioni-di-vendita-ESHOP_MYLITELED.pdf" target="_blank">Termini e
                        Condizioni</a></li>
                <li><a target="_blank" href="https://www.iubenda.com/privacy-policy/147874/legal"
                        class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                        title="Privacy Policy ">Privacy Policy</a></li>
                <li><a target="_blank" href="https://www.iubenda.com/privacy-policy/147874/legal"
                        class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                        title="Cookie Policy ">Cookie Policy</a></li>
            </ul>

            <div class="social-icons social-icons-color">
                <span class="social-label">Social Media</span>
                <a href="https://it.linkedin.com/company/myliteled" target="_blank" class="social-icon social-linkedin"
                    title="Linkedin"><i class="icon-linkedin"></i></a>
                <a href="https://www.facebook.com/myliteled/" target="_blank" class="social-icon social-facebook"
                    title="Facebook"><i class="icon-facebook-f"></i></a>
                <a href="https://www.instagram.com/myliteled" target="_blank" class="social-icon social-instagram"
                    title="Instagram"><i class="icon-instagram"></i></a>
                <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank"
                    class="social-icon social-youtube" title="Youtube"><i class="icon-youtube"></i></a>
                <a href="https://twitter.com/myliteled" target="_blank" class="social-icon social-x-twitter"
                    title="Twitter"><i class="icon-x-twitter"></i></a>
            </div>
        </div>
    </div>

    <div class="mb-10" *ngIf="isBottomSticky"></div>
</footer>
<script
    type="text/javascript">(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);</script>
<script
    type="text/javascript">(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);</script>